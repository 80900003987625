<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Report contratti</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>   
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <ion-grid class="ricerca">
          <ion-row>
            <ion-col>
              <ion-label id="lofferta">Rif. Offerta:</ion-label>
              <ion-input id="ricofferta" v-model="search.offerta"></ion-input>
            </ion-col>
            <ion-col>
              <ion-label id="lstato">Stato:</ion-label>
                <ion-select id="ricstato" v-model="search.stato">
                  <ion-select-option value="1">ATTIVO</ion-select-option>
                  <ion-select-option value="2">SOSPESO</ion-select-option>
                  <ion-select-option value="3">CHIUSO</ion-select-option>
                </ion-select>
            </ion-col>
          </ion-row>
          <ion-row id="buttons">
            <ion-button class="btncerca" @click="getContracts()">
                RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
        </ion-grid>
        <ion-button id="export" @click="esportaExcel()" style="width:5%;margin:1%;margin-left:0%;--ion-color-primary:#2c912f">
          <ion-icon :icon="downloadOutline"></ion-icon>
        </ion-button>
        <dtable
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="ContractHeading"
          :data="contracts"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getContracts"
        >   
          <template v-slot:stato="slotProps">
            <div v-if="slotProps.row.stato==1" style="background-color:#b5f5c6; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%); font-size:15px">ATTIVO</span>
            </div>     
            <div v-if="slotProps.row.stato==2" style="background-color:#fdff7a; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%); font-size:15px">SOSPESO</span>
            </div> 
            <div v-if="slotProps.row.stato==3" style="background-color:#fc9e97; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%); font-size:15px">CHIUSO</span>
            </div>           
          </template>
          <template v-slot:btns="slotProps">
            <ion-button class="btndettaglio" @click="$router.push('/folder/dettaglio-contratto/'+slotProps.row.id+'/'+slotProps.row.ref_off);">
              <ion-icon name="search-outline"></ion-icon>
            </ion-button>
          </template>
        </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
  } from '@ionic/vue';
  import Dtable from './partials/Dtable'
  import {downloadOutline} from 'ionicons/icons';


  export default {
    name: 'Folder',
    components: {
        IonButton,
        IonButtons,
        IonCol,
        IonContent,
        IonGrid,
        IonHeader,
        IonIcon,
        IonInput,
        IonLabel,
        IonMenuButton,
        IonPage,
        IonRow,
        IonSelect,
        IonSelectOption,
        IonTitle,
        IonToolbar,
        Dtable
    },
    data: function(){
      return{
        ContractHeading: [
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
          {
            text: 'Stato',
            code: 'stato'
          },
          {
            text: '',
            code: 'btns'
          }
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'ref_off',
          order: 'desc',
        },
        contracts:[],
        search:{
          offerta:'',
          stato:'1',
        },
        downloadOutline:downloadOutline,
      }
    },
    methods:{
      getContracts(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-contracts', { page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search}, this.headers)
        .then(async (response) => {
          this.contracts = response.data.contracts.data
          this.table.total = response.data.contracts.total
          this.table.perPage = response.data.contracts.per_page
        })
      },
      svuotaFiltro(){
        this.search.offerta='';
        this.search.stato='';
        this.getContracts();
      },
      esportaExcel(){
        window.open('http://backend.hbj-group.com/download-excel-contracts?&search.ref_off='+this.search.offerta + '&stato=' + this.search.stato + '&token='+this.$store.state.apiToken,'_blank')
      },
    }, 
    ionViewWillEnter: function(){
      this.getContracts();

    }
  }
</script>

<style scoped>
  #container{
    width:90%;
    margin:5%; 
  }
  #ricofferta,#ricstato{
    border:1px solid rgb(184, 184, 184);
    border-radius:5px;
  }
  #lofferta, #lstato{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  .ricerca{
    margin:10px;
    border:1px solid grey;
    border-radius:5px;
    margin-bottom:5%;
  }
  .btndettaglio{
    width:50%
  }
  .btncerca{
    width:92%;
  }
  .btnfiltro{
    width:5%;
  }
  @media only screen and (max-width: 600px) {
    .btncerca{
      width:80%;
    }
    .btnfiltro{
      width:10%;
    }
    #buttons{
      display:inline;
    }
  }
</style>